import axios from "axios";

export const postHandleIncompletePayment = (payment: Pi.PaymentDTO) => {
  return axios.post<Pi.PaymentDTO>(process.env.REACT_APP_BACKEND_URL + "/incomplete", { payment });
};
export const postHandlePaymentApproval = (paymentId: string) => {
  return axios.post<Pi.PaymentDTO>(process.env.REACT_APP_BACKEND_URL + "/approve", { paymentId });
};
export const postHandlePaymentCompletion = (paymentId: string, txid: string) => {
  return axios.post<Pi.PaymentDTO>(process.env.REACT_APP_BACKEND_URL + "/complete", { paymentId, txid });
};
export const postHandleCanceledPayment = (paymentId: string) => {
  return axios.post<Pi.PaymentDTO>(process.env.REACT_APP_BACKEND_URL + "/canceled", { paymentId });
};
