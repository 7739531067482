import { useState } from "react";
import { assertPiSdkErrorShape } from "../../utils";
import { Code } from "../../components/Code";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { Column } from "../../components/lib";

const SdkWallet = () => {
  const [xdr, setXdr] = useState<string>("");
  const [signTransactionRes, setSignTransactionRes] = useState<Pi.SdkResponse | null>(null);

  const signTransaction: React.FormEventHandler<HTMLFormElement> = async (e) => {
    setSignTransactionRes(null);

    e.preventDefault();

    try {
      const res = await Pi.Wallet.signTransaction(xdr);
      setSignTransactionRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setSignTransactionRes({ type: "error", error: err.message });
    }
  };

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Test Pi SDK Wallet
      </Typography>

      <Box mb={2} component="form" onSubmit={signTransaction}>
        <FormControl fullWidth>
          <TextField value={xdr} size="small" label="XDR" onChange={(e) => setXdr(e.target.value)} />
        </FormControl>
        <Button type="submit" variant="contained" size="small">
          signTransaction
        </Button>
        {signTransactionRes?.type === "error" ? (
          <Code mt={1} type="error">
            {signTransactionRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {signTransactionRes?.value}
          </Code>
        )}
      </Box>
    </Column>
  );
};

export default SdkWallet;
